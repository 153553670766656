<template>
  <v-row>
    <v-col
      cols="6"
      xs="6"
      class="text-left"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="info darken-2"
        :to="{ name: 'njangi.view_groups' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-eye"
          class="pe-1"
        />
        View Groups
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      xs="5"
      class="text-right"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'njangi.accept_requests' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Add User into Group
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-row class="pt-4 mx-2 pt-0">
          <v-col
            cols="12"
          >
            <v-select
              v-model="selectedRequestFilter"
              ref="selectedRequestFilter"
              outlined
              dense
              :items="requested_types"
              label="Filter Request By"
            ></v-select>
          </v-col>

          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-end"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getRequestedJoin()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="">
          <span class="me-3">({{ joinRequests.length }}) Join Requests </span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="joinRequests"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.applicant`]="{item}">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.applicant }}</span>
              <small><br><b>Contact:</b> {{ item.applicant_contact }} </small>
              <small><br><b>Job:</b> {{ item.job_description }} </small>
              <small><br><b>Bank:</b> {{ item.bank_account_info }} </small>
          </template>
          <template #[`item.id_card_names`]="{item}">
            <small><br><b>ID Name:</b> {{ item.id_card_names }}</small>
            <small><br><b>ID Number:</b> {{ item.id_card_number }}</small>
            <small><br><b>Issue Date:</b> {{ item.id_issue_date }}</small>
          </template>
          <template #[`item.njangi_contrib_interval`]="{item}">
            <small><br><b>Type:</b> {{ item.njangi_contrib_interval }}</small>
            <small><br><b>Amount:</b> {{ item.prefered_contrib_amount }}</small>
            <small><br><b>Assets:</b> {{ item.assets_info }}</small>
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip small
              :color="statusColor[item.status]"
              >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.get_date_str`]="{item}">
            <small>{{ item.get_date_str }}</small>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Client User Name', value: 'applicant' },
        { text: 'ID Card Info', value: 'id_card_names' },
        { text: 'Njangi Info', value: 'njangi_contrib_interval' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      joinRequests: [],
      selectedRequestFilter: '',
      requested_types: ['All', 'Pending', 'Validated', 'Cancelled'],
      selectedCategory: "",
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      statusColor,
      search: '',
      setDialog: false,
      selectedTransaction: null,
      dialogTitle: "Set Status For Transaction",
      transactionDetails: "",
      pendingState: false,
      validatedState: false,
      cancelledState: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Njangi | Join Requests"
  },

  methods:{
    changeSwitch(transaction_id){
      axios
        .get('/api/v1/manager/get/requested_transaction/'+this.selectedCategory+'/'+transaction_id+'/')
        .then(response => {
          const username = response.data[0]["username"]
          const amount = response.data[0]["amount"]
          this.selectedTransaction = transaction_id
          const campaign = response.data[0]["campaign_name"]
          const loan = response.data[0]["loan_name"]

          if (response.data[0]["campaign_name"]){
            this.transactionDetails = "Change the state of this requested deposit of amount <b>'"+amount+"'</b> on the campaign <b>'"+campaign+"'</b> by <b>'"+username+"'</b>"
          }
          else{
            this.transactionDetails = "Change the state of this requested deposit of amount <b>'"+amount+"'</b> on the loan <b>'"+loan+"'</b> by <b>'"+username+"'</b>"
          }
          console.log(response.data)

          this.pendingState = response.data[0]["pending_state"]
          this.validatedState = response.data[0]["validated_state"]
          this.cancelledState = response.data[0]["cancelled_state"]
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeTransactionState(){
      if (!this.pendingState && !this.validatedState && !this.cancelledState){
        this.$store.commit('setSnackBarMessage', "Error: Please select a state")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          
      }
      else if (!this.selectedCategory || this.selectedCategory === ''){
        this.selectedCategory = 'Loans'
      }
      else{
        let formData = new FormData()
        formData.append('pendingState', this.pendingState)
        formData.append('validatedState', this.validatedState)
        formData.append('cancelledState', this.cancelledState)
        formData.append('category', this.selectedCategory)

        axios
          .post('/api/v1/manager/change/requested_transaction/deposit/'+this.selectedTransaction+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            this.getRequestedJoin()
            this.setDialog = false
            console.log("Hello man")
          })
          .catch(error => {
            this.setDialog = false
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
      }
    },

    async getRequestedJoin(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (this.selectedRequestFilter === ''){
        this.$store.commit('setSnackBarMessage', "Please select a category to filter ")
        this.$store.commit('setSnackBarColor', "primary darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
        this.$refs.selectedRequestFilter.focus()  // this causes a an auto fucos to the element
      }
      else{
        this.loading = true
        console.log(this.loading)
        await axios
          .get('/api/v1/manager/njangi/get/join_requests/'+this.selectedRequestFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
          .then(response => {
            this.joinRequests = response.data  // get the data and fill into campaigns
            console.log(this.joinRequests)

            if (response.data[0].category === "error"){
              this.$store.commit('setSnackBarMessage', response.data[0].text)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }
          })
          .catch(error => {
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }
          })
        this.loading = false
      }
    },
  },
}
</script>
